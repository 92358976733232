
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, GameClient } from '@/services/Services';
import ErrorModal from '@/modals/errorModal.vue';
import RegisterSuccessModal from '../modals/registerSuccessModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Register extends Vue {

    stages: OM.TextIdentifier[] = [];
    registerModel: OM.RegisterUserVM = new OM.RegisterUserVM();

    loaded: boolean = false;

    created() {
        this.registerModel.personalData.birthDate = null;
        GameClient.getAvailableStages()
        .then(x => {
            this.stages = x;
            this.loaded = true;
        })
    }

    register() {       
        AppUserClient.registerUser(this.registerModel)
        .then(x => {
            this.$opModal.show(RegisterSuccessModal, {
                email: this.registerModel.contactData.emailAddress
            })
        })
        .catch(y => {
            this.$opModal.show(ErrorModal, {
                title: this.$localizationService.getLocalizedValue('app_Ops!', 'Ops!'),
                description: this.$localizationService.getLocalizedValue('app_UtenteGiaRegistrato', 'Utente già registrato')
            })
        })
    }

    get privacyLinkLocalized() {
        var ris = "https://www.MBMIdays.com/it_IT/privacy-policy/";
        if(store.state.selectedLanguage == "en-US")
            ris = "https://www.MBMIdays.com/en_EN/privacy-policy/"
        
        return ris;
    }

    // || !this.registerModel.personalData.birthDate
    get disabled() {
        return !this.registerModel.stageEventIdentifier || !this.registerModel.personalData.name || !this.registerModel.personalData.surname 
            || !this.registerModel.personalData.postalCode || !this.registerModel.personalData.city || !this.registerModel.contactData.phoneNumber
            || !this.registerModel.contactData.emailAddress || !this.registerModel.password 
            || !this.registerModel.rulesContest || !this.registerModel.privacy1 || !this.registerModel.privacy2;
    }

}
