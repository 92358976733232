import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "register"
}
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = { class: "d-flex align-items-start mb-3" }
const _hoisted_5 = {
  href: "/doc/regolamento_ufficiale_webapp.pdf",
  target: "_blank",
  class: "brandColor text-decoration-none",
  style: {"display":"contents"}
}
const _hoisted_6 = { class: "d-flex align-items-start mb-3" }
const _hoisted_7 = {
  href: "/doc/Informativa_IDays.pdf",
  target: "_blank",
  class: "brandColor text-decoration-none",
  style: {"display":"contents"}
}
const _hoisted_8 = { class: "d-flex align-items-start mb-3" }
const _hoisted_9 = { class: "d-flex align-items-start mb-3" }
const _hoisted_10 = { class: "d-flex align-items-start mb-3" }
const _hoisted_11 = { class: "d-flex align-items-start" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_localized_text, {
            type: "h1",
            localizedKey: "app_Occhio<br />alla registrazione!",
            text: "Occhio<br />alla registrazione!"
          }),
          _createVNode(_component_localized_text, {
            type: "p",
            localizedKey: "app_Per partecipare devi inserire i tuoi dati reali, altrimenti non potremo contattarti in caso di vincita e… quegli splendidi biglietti andranno a qualcun altro!",
            text: "Per partecipare devi inserire i tuoi dati reali, altrimenti non potremo contattarti in caso di vincita e… quegli splendidi biglietti andranno a qualcun altro!",
            class: "text mt-3"
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_form_field, {
            type: "select",
            modelValue: _ctx.registerModel.stageEventIdentifier,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.registerModel.stageEventIdentifier) = $event)),
            list: _ctx.stages,
            placeholderKey: "app_Scegli evento*",
            placeholderValue: "Scegli evento*"
          }, null, 8, ["modelValue", "list"]),
          _createVNode(_component_form_field, {
            type: "text",
            modelValue: _ctx.registerModel.personalData.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.registerModel.personalData.name) = $event)),
            placeholderKey: "app_Nome*",
            placeholderValue: "Nome*"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_field, {
            type: "text",
            modelValue: _ctx.registerModel.personalData.surname,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.registerModel.personalData.surname) = $event)),
            placeholderKey: "app_Cognome*",
            placeholderValue: "Cognome*"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_field, {
            type: "text",
            modelValue: _ctx.registerModel.personalData.postalCode,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.registerModel.personalData.postalCode) = $event)),
            placeholderKey: "app_CAP*",
            placeholderValue: "CAP*"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_field, {
            type: "text",
            modelValue: _ctx.registerModel.personalData.city,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.registerModel.personalData.city) = $event)),
            placeholderKey: "app_Città di residenza*",
            placeholderValue: "Città di residenza*"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_field, {
            type: "text",
            modelValue: _ctx.registerModel.contactData.phoneNumber,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.registerModel.contactData.phoneNumber) = $event)),
            placeholderKey: "app_Cellulare*",
            placeholderValue: "Cellulare*"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_field, {
            type: "email",
            modelValue: _ctx.registerModel.contactData.emailAddress,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.registerModel.contactData.emailAddress) = $event)),
            placeholderKey: "app_Email*",
            placeholderValue: "Email*"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_field, {
            class: "pb-3",
            type: "password",
            modelValue: _ctx.registerModel.password,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.registerModel.password) = $event)),
            placeholderKey: "app_Password*",
            placeholderValue: "Password*"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'checked': _ctx.registerModel.rulesContest}]),
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.registerModel.rulesContest = !_ctx.registerModel.rulesContest))
            }, null, 2),
            _createElementVNode("a", _hoisted_5, [
              _createVNode(_component_localized_text, {
                localizedKey: "app_Confermo di aver preso visione del <u>regolamento</u> del concorso “Vinci i biglietti I Days 2024” e accetto i termini del medesimo",
                text: "Confermo di aver preso visione del <u>regolamento</u> del concorso “Vinci i biglietti I Days 2024” e accetto i termini del medesimo",
                class: "fs_12"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'checked': _ctx.registerModel.privacy1}]),
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.registerModel.privacy1 = !_ctx.registerModel.privacy1))
            }, null, 2),
            _createElementVNode("a", _hoisted_7, [
              _createVNode(_component_localized_text, {
                localizedKey: "app_Confermo di aver preso visione dell'<u>informativa</u> privacy qui indicata, ex REG UE 679/2016 (GDPR)",
                text: "Confermo di aver preso visione dell'<u>informativa privacy</u> qui indicata, ex REG UE 679/2016 (GDPR)",
                class: "fs_12"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'checked': _ctx.registerModel.privacy2}]),
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.registerModel.privacy2 = !_ctx.registerModel.privacy2))
            }, null, 2),
            _createVNode(_component_localized_text, {
              localizedKey: "app_Presto il consenso per le attività di Marketing diretto iniziative del Centro Commerciale (paragrafo “Finalità del trattamento” lettera a)",
              text: "Presto il consenso per le attività di Marketing diretto iniziative del Centro Commerciale (paragrafo “Finalità del trattamento” lettera a)",
              class: "fs_12"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'checked': _ctx.registerModel.privacy3}]),
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.registerModel.privacy3 = !_ctx.registerModel.privacy3))
            }, null, 2),
            _createVNode(_component_localized_text, {
              localizedKey: "app_Presto il consenso per le attività di marketing per prodotti di terzi presenti all’interno del Centro Commerciale (paragrafo “Finalità del trattamento” lettera b)",
              text: "Presto il consenso per le attività di marketing per prodotti di terzi presenti all’interno del Centro Commerciale (paragrafo “Finalità del trattamento” lettera b)",
              class: "fs_12"
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'checked': _ctx.registerModel.privacy4}]),
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.registerModel.privacy4 = !_ctx.registerModel.privacy4))
            }, null, 2),
            _createVNode(_component_localized_text, {
              localizedKey: "app_Presto il consenso per le attività di Marketing diretto servizi del Centro Commerciale (paragrafo “Finalità del trattamento” lettera c)",
              text: "Presto il consenso per le attività di Marketing diretto servizi del Centro Commerciale (paragrafo “Finalità del trattamento” lettera c)",
              class: "fs_12"
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'checked': _ctx.registerModel.privacy5}]),
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.registerModel.privacy5 = !_ctx.registerModel.privacy5))
            }, null, 2),
            _createVNode(_component_localized_text, {
              localizedKey: "app_Presto il consenso all’uso dei miei dati personali per migliorare la mia esperienza di navigazione e ricevere proposte in linea con i miei interessi attraverso l’analisi dei miei precedenti acquisti (paragrafo “Finalità del trattamento” lettera d)",
              text: "Presto il consenso all’uso dei miei dati personali per migliorare la mia esperienza di navigazione e ricevere proposte in linea con i miei interessi attraverso l’analisi dei miei precedenti acquisti (paragrafo “Finalità del trattamento” lettera d)",
              class: "fs_12"
            })
          ]),
          _createElementVNode("button", {
            class: "btn btn-primary w-100 mt-5 mb-4",
            disabled: _ctx.disabled,
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.register()))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_Registrati",
              text: "Registrati"
            })
          ], 8, _hoisted_12)
        ])
      ]))
    : _createCommentVNode("", true)
}